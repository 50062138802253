import { Link } from "@StarberryUtils";
import React, { useEffect, useState } from "react";
import { Col, Row, Container, Modal } from 'react-bootstrap';
import ReactMarkdown from "react-markdown"

function Fixflow(props) {

  const [show, setShow] = useState(true);

    return (
        <div className="content">
            {props.Modules.map((Modules, i) => {
                return (
                    <>
                    {Modules.Embed_URL &&
                        <Container className="fixflow-div">
                            <Row>
                                <Col lg={12}>
                                    <div className="fixflow">
                                        {Modules.Intro_Content ?
                                            <ReactMarkdown source={Modules.Intro_Content} allowDangerousHtml className="editor"/> : ''
                                        }
                                        <iframe src={Modules.Embed_URL}></iframe>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    }

                <Modal
                show={show}
                onHide={() => setShow(false)}
                className="fixflow-popup"
                >
                <Modal.Header closeButton>
                </Modal.Header>
                    <Modal.Body>
                        {Modules.Popup_Content &&
                        <ReactMarkdown source={Modules.Popup_Content} allowDangerousHtml className="editor"/>
                        }
                        {Modules.Popup_CTA_Text &&
                        <button className="btn btn-primary" onClick={() => setShow(false)}>{Modules.Popup_CTA_Text}</button>
                        }
                    </Modal.Body>
                </Modal>


                    </>
                )
            })}
        </div>
    )
}

export default Fixflow
