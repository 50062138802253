import React, { useState, useEffect } from "react"

import {Row, Col, Container,Breadcrumb, Form, Button } from 'react-bootstrap';
import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import RadioField from './elements/radio'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
//import Valpal from './valpal-result'
import { postFormData } from "./api/Api"
import { getUser } from "@myaccountServices/store/utils"
import _ from "lodash";

import axios from "axios"
import * as qs from "query-string"
import $ from "jquery"
import loadable from '@loadable/component'

const OtherComponent = loadable(() => import('./elements/address-search'))
const Valpal = loadable(() => import('./valpal-result'))


function MySimpleForm(props) {

  const userData = getUser();
  const [startDate, setStartDate] = useState();
  const [valdatetime, setvaldatetime] = useState();
  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);
  const [valpalshow, setValpalshow] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const [errortext, setErrorText] = useState("Highlighted fields are required");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  // const [addressLabel, setAddressLabel] = useState('');

  useEffect(() => {

    $('#radio2').attr('checked',true)
    $('#letting_valuation').attr('checked',true)

    setAddressBuildingName(sessionStorage.getItem('addressBuildingName') != null ? sessionStorage.getItem('addressBuildingName') : '')
    setAddressBuildingNumber(sessionStorage.getItem('addressBuildingNumber') != null ? sessionStorage.getItem('addressBuildingNumber') : '')
    setAddressPostalCode(sessionStorage.getItem('addressPostalCode') != null ? sessionStorage.getItem('addressPostalCode') : '')
    setAddressSubBuilding(sessionStorage.getItem('addressSubBuilding') != null ? sessionStorage.getItem('addressSubBuilding') : '')
    setAddressStreet(sessionStorage.getItem('addressStreet') != null ? sessionStorage.getItem('addressStreet') : '')
    $("input[name=addressPostalCode]").val('');
    $("input[name=address]").val('');

  }, []);



  const [addressLabel, setAddressLabel] = useState('')
  const [addressBuildingName, setAddressBuildingName] = useState('')
  const [addressBuildingNumber, setAddressBuildingNumber] = useState('')
  const [addressPostalCode, setAddressPostalCode] = useState('')
  const [addressSubBuilding, setAddressSubBuilding] = useState('')
  const [addressStreet, setAddressStreet] = useState('')
  const [valpalsell, setValpalSell] = useState('')
  const [valpallet, setValpalLet] = useState('')
  const [valtiming, setValTiming] = useState('')



  const fields = ([
      {
        element: "config",
        formname: "Home visit valuation",
        form_type: "valuation",
        error_text: "Highlighted fields are required",
        success_text: "Thanks for choosing Martyn Gerrard to value your property. We will contact you as soon as possible.",
        email_temp_user: "valuation_user",
        email_temp_admin: "valuation_admin",
        email_server_func: "valuation",
        event_tracking: "valuation",
        page_url: "/market-your-property/book-a-valuation"
      },
      {
        grpmd: "12",
        label: "What's your property address?",
        placeholder: "Start typing your address *",
        name: "address",
        type: "text",
        element: "address",
        step: "stepone",
        required: true,
        patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
        labelClass: "content_b-18"
      },
      {
        grpmd: "6",
        label: "Sell",
        placeholder: "",
        name: "sell_let",
        type: "checkbox",
        element: "checkbox",
        step: "stepone",
        required: false,
        patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
        labelClass: "content_b-18"
      },
      {
        grpmd: "12",
        name: "Next",
        type:"button",
        element: "button",
        step: "stepone",
        value: "click",
        class: "btn btn-success stepone",
        labelClass: "content_b-18"
      },
      {
        grpmd: "12",
        label: "Please enter your details",
        placeholder: "Your Name *",
        name: "name",
        type: "text",
        element: "input",
        step: "steptwo",
        required: true,
        patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
        labelClass: "content_b-18",
        defaultValue: userData?.name || ``
      },
      {
        grpmd: "12",
        label: "",
        placeholder: "Your Email Address *",
        name: "email",
        type: "email",
        element: "input",
        step: "steptwo",
        required: true,
        patternchk:"[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
        labelClass: "content_b-18",
        defaultValue: userData?.email || ``

      },
      {
        grpmd: "12",
        label: "",
        placeholder: "Your Telephone Number *",
        name: "telephone",
        step: "steptwo",
        type: "text",
        element: "input",
        required: true,
        patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "content_b-18",
        defaultValue: userData?.mobile || ``

      },
      {
        grpmd: "12",
        name: "Next",
        type:"button",
        element: "button",
        step: "steptwo",
        value: "click",
        class: "btn btn-success steptwo",
        labelClass: "content_b-18"
      },
      {
        grpmd: "12",
        label: "",
        placeholder: "Select Time & Date *",
        name: "date_time",
        type: "datetime",
        element: "input",
        // min: today,
        // max: maxday.toString(),
        required: true,
        patternchk: "",
        class: "mb-4 form-control stepfour",
        step: "stepfour"
      },
      {
        grpmd: "12",
        label: "",
        placeholder: "",
        name: "time",
        type: "time_radio",
        element: "radio",
        step: "stepfour",
        required: true,
        labelClass: "content_b-18",
        class: "time"
      },
      {
        grpmd: "12",
        name: "Confirm",
        type:"button",
        element: "button",
        step: "stepfour",
        value: "click",
        class: "btn btn-success stepfour",
        labelClass: "content_b-18"
      },
      /*{
        text: 'Maecenas eget risus eget ex ornare commodo. Duis aliquet libero purus, at viverra nisi semper a.',
        element: "html",
        class: "head",
        type: "select",
        step: "stepfive"
      },*/
      {
        grpmd: "6",
        label: "Would you also like a lettings valuation?",
        placeholder: "Yes please!",
        name: "letting_valuation",
        type: "checkbox",
        element: "checkbox",
        step: "stepfive",
        required: false,
        patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
        labelClass: "content_b-18",
        class: "letting_val"
      },
      {
        grpmd: "12",
        label: "Why did you choose MG?",
        placeholder: "Select Reason",
        name: "reason",
        event_tracking_decider: "valuation",
        element: "select",
        required: true,
        type: "select",
        step: "stepfive",
        values: ['General Enquiry','Property Enquiry','Others'],
        class: "mb-4"
      },
      {
        grpmd: "12",
        label: "Anything else you’d like to tell us?",
        placeholder: "Write your message here...",
        name: "message",
        type: "text",
        element: "input",
        step: "stepfive",
        required: false,
        labelClass: "content_b-18"
      },
      {
        grpmd: "12",
        name: "Submit",
        type:"submit",
        element: "button",
        value: "submit",
        class: "btn btn-success",
        labelClass: "content_b-18",
        step: "stepfive"
      },
      {
        element: "captcha",
        class: "py-2 stepfive valuation-captcha",
        captchaRef: recaptchaRef
      },
    ]);

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }

  useEffect(() => {
    if (token !== '') {

 const processFromData = async () => {

      let formData = new FormData();

      formData.append('data', JSON.stringify(formvalues));

      postFormData(formData).then(apiRes => {

      // lets send mail
      const axiosOptions_email = {
        url: '/.netlify/functions/server/'+fields[0].email_server_func,
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(formvalues),
      }

      axios(axiosOptions_email)
        .then(response => {
          console.log('mail sent!')
        })
        .catch(err =>
          console.log(err)
        );
      });

      formvalues['g-recaptcha-response'] = token;


      const url = typeof window !== 'undefined' ? window.location.href : ''
      if (url.indexOf("property") > -1 && fields[4].event_tracking_decider) {
        fields[0].formname = 'Home visit valuation';
      }
      // tracking event
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'formSubmit',
        'formType': 'form-'+fields[0].event_tracking,
        'formId': 'form-'+fields[0].event_tracking,
        'formName': fields[0].formname,
        'formLabel': fields[0].formname
      });

      setShowerror(false);
      setThankyou(true);
      //myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  function onClickstep1() {
      setShowerror(true);
      setValidated(true);
      setValpalshow(false);
      var valaddr  = $("input[name=address]").val();
      var addressPostalCode  = $("input[name=addressPostalCode]").val();
      var sell  = document.getElementById("sell").checked;
      var letting  = document.getElementById("let").checked;
      if(sell == false && letting == false ) {
        setErrorText("Please Choose Sell or Let or Both")
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }
      if(sell == true) {
        setValpalSell("Sell");
      }
      if(letting == true) {
        setValpalLet("Let");
      }
      //alert(addressPostalCode)
      if(valaddr.length === 0 || addressPostalCode.length === 0) {
        setErrorText("Please enter your valid property address")
        $("input[name=address]").val('')
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }

      if(valaddr && addressPostalCode && (sell == true || letting == true)) {
        setShowerror(false);
        setValidated(false);
        $('.valuation-main .stepone').hide();
        $('.valuation-main .steptwo').show();
        document.getElementById("form-head").innerHTML = "Now we need your personal details.";


      }
  }
  function onClickstep2() {
    setShowerror(true);
    setValidated(true);
    setValpalshow(true);
    var valname   = $("input[name=name]").val();
    var valemail     = $("input[name=email]").val();
    var valphone     = $("input[name=telephone]").val();

    var phoneNum = /^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$/;
    var telvalidation = false
    var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    var emailvalidation = false

      if(valemail.match(mailformat)) {
        emailvalidation = true
      } else {
        emailvalidation = false
      }

      if(valphone.match(phoneNum)) {
        telvalidation = true
      } else {
        telvalidation = false
      }


    if(valname && valemail && valphone && emailvalidation == true && telvalidation == true) {
        setShowerror(false);
        setValidated(false);
        $('.valuation-main .stepone').hide();
        $('.valuation-main .steptwo').hide();
        $('.valuation-main .stepfour').show();
        document.getElementById("form-head").innerHTML = "Please choose your preferred date and time.";

    }
    else {
        if(!valname && !valemail && !valphone)
        {
        setErrorText("Please enter all the details")
        }
        else {
        if(emailvalidation == false) {
          setErrorText("Please enter valid email address")
        }
        else if(telvalidation == false) {
          setErrorText("Please enter valid phone number")
        }
        }
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
          $("#nosalesdata").hide();
          $("#norentalsdata").hide();
          $("#salesdata").show();
          $("#rentalsdata").show();
  }

  function onClickstep3() {
    setValpalshow(false);
        $('.valuation-main .stepone').hide();
        $('.valuation-main .steptwo').hide();
        $('.valuation-main .stepthree').hide();
        $('.valuation-main .stepfour').show();
        $('.valuation-main .stepfive').hide();
        document.getElementById("form-head").innerHTML = "Please choose your preferred date and time.";
  }

  function onClickstep4() {
    setValpalshow(false);
    setShowerror(true);
    setValidated(true);
    var valdate   = $("input[name=valuation_book_date]").val();
    var valtime   = $("input[name=val_time]:checked").val();
    if(valdate && valtime) {
        setShowerror(false);
        setValidated(false);
        $('.valuation-main .stepfour').hide();
        $('.valuation-main .stepfive').show();
        setValTiming(valtime);
        document.getElementById("form-head").innerHTML = "You're almost done! Please submit the form to complete your request.";
    }
    else {
        setErrorText("Please select time")
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }

  function onClickbackstep1() {
    setValpalshow(false);
        setShowerror(false);
      $('.valuation-main-landing').show();
      $('.valuation-sub-pages').hide();
  }

  function onClickbackstep2() {
    setValpalshow(false);
        setShowerror(false);
    $('.valuation-main .steptwo').hide();
    $('.valuation-main .stepone').show();
    setValpalSell('');
    setValpalLet('');
    document.getElementById("form-head").innerHTML = "Firstly, we need some<br/>property information.";
  }


  function onClickbackstep3() {
    setValpalshow(false);
        setShowerror(false);
    $('.valuation-main .stepfour').hide();
    $('.valuation-main .steptwo').show();
    document.getElementById("form-head").innerHTML = "Now we need your personal details.";

  }

  function onClickbackstep4() {
    setValpalshow(false);
        setShowerror(false);
    $('.valuation-main .steptwo').show();
    $('.valuation-main .stepfour').hide();
    document.getElementById("form-head").innerHTML = "All done! Here are the approximate sale and rental values for your property.";

  }


  const handleSubmit = event => {


    const form = event.currentTarget;
    const formsdata = (event.target);
    setvaldatetime($("input[name=date_time]").val())
    if (form.checkValidity() === false) {
        setErrorText("Please choose the reason to submit")
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    else {
      $('.valuation-main .stepfive').hide();
      $('.event-wrap').hide();
      $('.main-event-wrap').show();
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      $('.stbform').hide();
      $('.valuation-success-btns').show();
      var valdate   = $("input[name=valuation_book_date]").val();
      var valtime   = $("input[name=val_time]:checked").val();
      document.getElementById("form-head").innerHTML = "Thanks for choosing Martyn Gerrard to value your property. We will contact you as soon as possible.";
      event.preventDefault();

      const formsdata = (event.target);


      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))


      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;
      json['extra'] = _.omit(json, ["formname", "name", "email", "telephone"])
      setFormvalues(json);

      recaptchaRef.current.execute();

      setValidated(false);

      // reset form
      const form = event.target
      form.reset();
      setStartDate()
    }
  };


  //var valdatetime = $("input[name=date_time]").val();

  return (
    <div className="form stbform valuation-main">


    {showerror && <div className="alert-error">
      <p>{errortext}</p>
    </div>}

    <div ref={myRef} />


      <Form id="valuationform" className="contact-form" name={fields[0].formname} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit} autoComplete={false}>
      <div className="step-block">


    <div className="stepthree">
      <input id="addressLabel" value="" type="hidden" name="addressLabel" />

      <input id="addressBuildingName" value="" type="hidden" name="addressBuildingName" />

      <input id="addressBuildingNumber" value="" type="hidden" name="addressBuildingNumber" />

      <input id="addressPostalCode" value={addressPostalCode} type="hidden" name="addressPostalCode" />

      <input id="addressSubBuilding" value="" type="hidden" name="addressSubBuilding" />

      <input id="addressStreet" value="" type="hidden" name="addressStreet" />


    <Row className="step-btns">
      <Col md={5}>
        <Button onClick={onClickbackstep3}  variant="" type="button" className="btn btn-success stepthree">
          Go Back
        </Button>
      </Col>
      <Col md={2}>
      </Col>
      <Col md={5}>
        <Button onClick={onClickstep3}  variant="" type="button" className="btn-step btn-step-next btn btn-primary testtttt1">
          Next
        </Button>
      </Col>
    </Row>

    </div>


        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="to_email_id" value={props.to_email_id} />
        <input type="hidden" name="office" value={props.office} />
        <input type="hidden" name="office_email" value={props.officeEmail} />
        <input type="hidden" name="timing" value={valtiming} />
        <input type="hidden" name="bot-field" />

                <div className="stepone">
                <OtherComponent addressLabel={addressLabel}/>
                </div>

        {fields.map((field, index) => {

            if ( "input" === field.element ) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  type={field.type}
                  step={field.step}
                  startDate={field.type === 'datetime' ? startDate : ''}
                  setStartDate={field.type === 'datetime' ? setStartDate : ''}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  label={field.label}
                  defaultValue={field.defaultValue}
                />
              );
            }

            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  type={field.type}
                  step={field.step}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                  label={field.label}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  type={field.type}
                  step={field.step}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                  label={field.label}
                />
              );
            }
            if ("radio" === field.element) {
              return (
                <RadioField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  checked={field.checked}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                  step={field.step}
                  type={field.type}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                  step={field.step}
                />
              );
            }
            if ("heading" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                  step={field.step}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  type={field.type}
                  step={field.step}
                  value={field.value}
                  key={`${field.element}~${index}`}
                  handlechange={onClickstep1}
                  handlechange2={onClickstep2}
                  handlechange4={onClickstep4}
                  handlebackchange={onClickbackstep1}
                  handlebackchange2={onClickbackstep2}
                  handlebackchange3={onClickbackstep3}
                  handlebackchange4={onClickbackstep4}
                />
              );
            }
          })
        }
            </div>
      </Form>
    </div>
  );
}


const ValuationFormPage = (props) => (
  <MySimpleForm to_email_id={props.to_email_id} office={props.office} officeEmail={props.officeEmail} />
)

export default ValuationFormPage
