import { Link } from "@StarberryUtils";
import PropTypes from "prop-types";
import React,{useState, useEffect} from "react";
import {Col, Row, Container } from 'react-bootstrap';
import { useStaticQuery, graphql } from "gatsby";
import axios from "axios"
import ShowMoreText from 'react-show-more-text';
import Score from '../reviewScore'

import { ReviewDate } from "../../common/site/functions";

import  "./Intro.scss";
import GoogleReview from "../../../images/google reviews logo.svg"

const postsPerPage = 24;
let arrayForHoldingPosts = [];


const GoogleReviews = () => {
     /**** set here local states */
  const [] = useState(0);

  const [postsToShow, setPostsToShow] = useState([]);
  const [next, setNext] = useState(24);

  const [] = useState(true);
  var testimonials = []
  
  const data = useStaticQuery(graphql`
    query{
      allStrapiGoogleReviewReviews(filter: {starRating: {in: ["FIVE", "FOUR", "THREE"]}, comment: {ne: null}}) {
        edges {
          node {
            name
            starRating
            comment
            createTime
            reviewer {
              displayName
              profilePhotoUrl
            }
          }
        }
        totalCount
      }
    }
  `);


  testimonials = data.allStrapiGoogleReviewReviews.edges


  const loopWithSlice = (start, end) => {
    const slicedPosts = testimonials.slice(start, end);
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
    //console.log("review",arrayForHoldingPosts)
    setPostsToShow(arrayForHoldingPosts);
  };

  const handleShowMorePosts = () => {
    loopWithSlice(next, next + postsPerPage);
    setNext(next + postsPerPage);
  };



    // const getitems = async url => {
    //   try {
    //     const { data } = await axios.get(url, {
    //   })// could be from env files
    //     setTestimonials(data)
    //   } catch (error) {
    //     // cache it if fail/error;
    //     console.error(error)
    //   }
    // }

  useEffect(() => {
    


    loopWithSlice(0, postsPerPage);
  }, []);

 /** end local state area */
//  useEffect(() => {
//     let url = `${process.env.GATSBY_STRAPI_SRC}/google-review-reviews`
//     getitems(url);

// }, [])

    return(
        <div className="static-reviews">
            
        <Container>
          <div className="review-head d-md-flex justify-content-between align-items-center">
            <div className="google-reviews d-md-flex align-items-center">
                  <img loading="lazy" src={GoogleReview} alt="google-review" />
                  <div className="info"> 
                  <Score />
                    
                    <span className="txt">We’d love for you to <a href="https://www.google.com/search?q=martyn+gerrard+head+office&ei=O5_2YLrfON-c4-EPm626gAc&gs_ssp=eJzj4tZP1zcsSSnOMq8qM2C0UjWoMLEwNzO0TEuxNE4yN0s2SLIyqEgyMjQyMTA0Tks1TTE3NE_1kspNLCqpzFNITy0qSixKUchITUxRyE9Ly0xOBQCt5xiq&oq=martyn+gerrard+head&gs_lcp=Cgdnd3Mtd2l6EAMYADILCC4QxwEQrwEQkwIyCQgAEMkDEBYQHjIGCAAQFhAeMgYIABAWEB46BAgAEEc6CAguEMcBEK8BOgoILhDHARCvARBDOgQIABBDOgUIABDJAzoCCABKBQg6EgExSgQIQRgAUN8QWN4dYIUkaABwA3gAgAHSA4gB-hOSAQUzLTEuNZgBAKABAaoBB2d3cy13aXrIAQjAAQE&sclient=gws-wiz" target="_blank"> leave us a review</a>.</span>
                  </div>
            </div>
          </div>
          <Row>
          {postsToShow.map((review) => {
            return (
            <Col xl={6}>
              <div className="review-card">
                <div className="rate-stars">
                    {review.node.starRating== "ONE" &&
                        <i className="icon-fill-star"></i>  
                    }
                    {review.node.starRating== "TWO" &&
                        <>
                        <i className="icon-fill-star"></i>  
                        <i className="icon-fill-star"></i> 
                        </>
                    }
                    {review.node.starRating== "THREE" &&
                        <>
                        <i className="icon-fill-star"></i>  
                        <i className="icon-fill-star"></i>  
                        <i className="icon-fill-star"></i>  
                        </>
                    }
                    {review.node.starRating== "FOUR" &&
                        <>
                        <i className="icon-fill-star"></i>  
                        <i className="icon-fill-star"></i>  
                        <i className="icon-fill-star"></i>  
                        <i className="icon-fill-star"></i>  
                        </>
                    }
                    {review.node.starRating== "FIVE" &&
                        <>
                        <i className="icon-fill-star"></i>  
                        <i className="icon-fill-star"></i>  
                        <i className="icon-fill-star"></i>  
                        <i className="icon-fill-star"></i>  
                        <i className="icon-fill-star"></i>  
                        </>
                    }                   
                </div>
                {review.node.comment &&
                <ShowMoreText
                /* Default options */
                lines={3}
                more='More'
                less='Less'
                className='google-review-content-css desc'
                anchorClass='btn-more review-more'
                expanded={false}
                keepNewLines={false}
                >

                  {review.node.comment}
                </ShowMoreText>
                }

                <strong className="name">{review.node.reviewer.displayName}</strong>
                <span className="date">{ReviewDate(review.node.createTime)}</span>
              </div>
            </Col>
            )
          })}

      <div className="pagination-btn text-center w-100">
      <button className="btn d-block d-md-inline-block btn-pagination" onClick={handleShowMorePosts}>Load more</button>
          </div>

          </Row>
        </Container>
      </div> 
    )
}


export default GoogleReviews