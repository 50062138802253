import { Link } from "@StarberryUtils";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row, Container } from 'react-bootstrap';
import "../Intro/Intro.scss";
import VideoPlay from "../../Play/news-video-with-background";
import ReactMarkdown from "react-markdown"
import Breadcrumbs from "../../MgServices/Breadcrumb/Breadcrumb"
import { getSrc } from "gatsby-plugin-image"
import GenerateLink from "../../common/site/generate-link";

import { inViewOptions } from '../../utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerVariants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4
    }
  }
}
const contentVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.2,
      ease: [0.33, 1, 0.68, 1],
      duration: 0.8
    }
  }
}
const sidebarVariants = {
  hidden: {
    opacity: 0,
    y: -30,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.4,
      ease: [0.33, 1, 0.68, 1],
      duration: 0.8
    }
  }
}
const MotionColumn = motion(Col)

const StaticPage = (
  props,
  // { siteTitle }
) => {

  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          className="managing-sec managing-intro static-news-page"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <div className="rental-market">
            <Breadcrumbs id={props.id} />
            <Container>

              <Row>
                <MotionColumn className="order-2 order-xl-1" xl={8} variants={contentVariants}>
                  <div className="rental-post news-content">
                    {props.Modules.map((Modules, i) => {
                      return (
                        <React.Fragment key={i}>
                          {Modules.Content ?
                            <ReactMarkdown source={Modules.Content} allowDangerousHtml className="editor" /> : ''
                          }

                          {Modules.Background_Image ?
                            <VideoPlay image={Modules.Background_Image.url} url_1={Modules.Embed_Video_URL} url_2={Modules.Upload_Video} /> : ''
                          }

                          {Modules.Add_Image ?
                            <img loading="lazy" className="banner-img static-page-inner-image" src={getSrc(Modules.Add_Image)} alt='' /> : ''
                          }
                        </React.Fragment>
                      )
                    })}
                  </div>
                </MotionColumn>
                <MotionColumn className="order-2 order-xl-2" xl={4} variants={sidebarVariants}>
                  <div className="get-quote-right-side-block">
                    <div className="get-quote d-xl-block">
                      {props.Modules.map((Modules, i) => {
                        //console.log("Modules => ", Modules)
                        return (
                          <>
                            {i == 0 &&
                              <React.Fragment key={i}>

                                <h3 className="get-title">{Modules.Right_Side_Block_Title ? Modules.Right_Side_Block_Title : 'Interested in Selling or Letting your property?'}</h3>
                                {(Modules.Right_Side_Block_CTA_Link && Modules.Right_Side_Block_CTA_Label) ?
                                  <GenerateLink link={Modules.Right_Side_Block_CTA_Link} class="btn full-width-btn">
                                    {Modules.Right_Side_Block_CTA_Label}
                                  </GenerateLink> :
                                  <Link to='/market-your-property/book-a-valuation/' class="btn full-width-btn">Book your Valuation</Link>
                                }

                                {Modules.Right_Side_Block_Title_Two &&
                                  <h3 className="get-title second">{Modules.Right_Side_Block_Title_Two}</h3>
                                }
                                {(Modules.Right_Side_Block_CTA_Link_Two && Modules.Right_Side_Block_CTA_Label_Two) &&
                                  <GenerateLink link={Modules.Right_Side_Block_CTA_Link_Two} class="btn full-width-btn">
                                    {Modules.Right_Side_Block_CTA_Label_Two}
                                  </GenerateLink>
                                }

                              </React.Fragment>
                            }
                          </>
                        )
                      })}
                      {props.Speak_with ?
                        <>
                          <p className="get-desc">We’re always here to help. Hit the button above or get in touch with {props.Speak_with.Name}.</p>


                          <div className="get-profile d-flex align-items-center">
                            <figure><img loading="lazy" src={`${props.Speak_with.Tile_Image.url}`} alt="profile-img" /></figure>
                            <div className="prof-info">
                              <span className="name">{props.Speak_with.Name}</span>
                              <a href={`tel:${props.Speak_with.Phone}`}>{props.Speak_with.Phone}</a>
                            </div>
                          </div></> : ''}

                    </div>

                    {props.Modules.Right_Side_Block_Make_Recommendation === "win-a-meal-for-two" ?
                    <div className="make-recommendation help-sec">
                      {props.Modules.map((Modules, i) => {
                        return (
                          <>
                            {i == 0 &&
                              <React.Fragment key={i}>
                                {Modules.Right_Side_Block_Make_Recommendation &&
                                  <h3 className="get-title second">{Modules.Right_Side_Block_Make_Recommendation}</h3>
                                }
                                {(Modules.Right_Side_Block_Link_Make_Recommendation && Modules.Right_Side_Block_CTA_Make_Recommendation) &&
                                  <GenerateLink link={Modules.Right_Side_Block_Link_Make_Recommendation} class="btn full-width-btn">
                                    {Modules.Right_Side_Block_CTA_Make_Recommendation}
                                  </GenerateLink>
                                }
                              </React.Fragment>
                            }
                          </>
                        )
                      })}
                    </div> : ''
                    }
                  </div>
                </MotionColumn>
              </Row>

            </Container>
          </div>
        </motion.section>
      )}
    </InView>
  )
}

StaticPage.propTypes = {
  siteTitle: PropTypes.string,
}

StaticPage.defaultProps = {
  siteTitle: ``,
}

export default StaticPage
