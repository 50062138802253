// import { Link } from "@StarberryUtils";
import { useStaticQuery, graphql } from "gatsby";
import React,{ useState } from "react";
import Slider from 'react-slick';
import {Col, Row, Container } from 'react-bootstrap';
// import ReactMarkdown from "react-markdown"
import  "../Reviews/Reviews.scss";
// import googleReview from "../../../images/google reviews logo.svg";
// import video6 from "../../../images/video-img08.jpg";
import Video from "../../Play/custom-video";

// import { GetURL } from "../../common/site/functions";

import { inViewOptions } from '../../utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerItem = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    }
  }
}
const contentItem = {
  hidden: { opacity: 0, y: 30 },
  visible: { opacity: 1, y: 0 }
}

const AllReviews =()=> {

  const settings = {
    dots: true,
    speed: 800,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    mobileFirst:true,
    autoplay: true,
    responsive: [
      {
          breakpoint: 9999,
          settings: "unslick"
      },
      {
          breakpoint: 767,
           settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
              }
      }
  ]
  }


    const settings_one = {
      className: "center main-list",
      infinite: true,
      slidesToShow: 3,
      speed: 500,
      rows: 2,
      slidesPerRow: 2,
      slidesToScroll: 1,
      //variableWidth: true,
      autoplay: false,
      autoplaySpeed: 6000,
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        }
      ]
    }


  const data = useStaticQuery(graphql`{
  allStrapiCustomerReviews(
    filter: {Publish: {eq: true}}
    sort: {fields: published_at, order: DESC}
  ) {
    edges {
      node {
        id
        Name
        Embed_Video_URL
        Image {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 250, layout: CONSTRAINED)
          }
        }
        strapiId
      }
    }
  }
}
`);

    const chunkSize = 2;
    const arr = data.allStrapiCustomerReviews.edges
    // const groups = arr.map((e, i) => {
    //      return i % chunkSize === 0 ? arr.slice(i, i + chunkSize) : null;
    // }).filter(e => { return e; });

    // const chunkSizeOne = 16;
    // const arrOne = data.allStrapiCustomerReviews.edges
    // const groupsOne = arrOne.map((e, i) => {
    //      return i % chunkSizeOne === 0 ? arrOne.slice(i, i + chunkSizeOne) : null;
    // }).filter(e => { return e; });

  return (
    <div className="reviewer-videos d-md-flex all-view-reviews">
      <InView {...inViewOptions}>
        {({ ref, inView }) => (
          <motion.section
            className="customers-reviews components-Home-Reviews-Reviews desktop-main-list"
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerItem}
          >
            <Container>
              <Row className="align-items-xl-center">
                <Col lg={12}>
                  <Slider className="reviews-slider main-list"  {...settings_one} >
                    {arr.map(({node}, i) => {
                      return (
                        <motion.li key={i} variants={contentItem}>
                          <div className={`sub-list id-${i}`}>
                            <div className="review-video">
                              <img loading="lazy" src={node.Image.childImageSharp.gatsbyImageData.images.fallback.src} alt={`${node.Name} - Martyn Gerrard`} />
                              <Video title={`${node.Name} Review`} url={node.Embed_Video_URL}/>
                            </div>
                            <div className="video-name">{node.Name}</div>
                          </div>
                        </motion.li>
                      );
                    })}
                  </Slider>
                </Col>
              </Row>
            </Container>
          </motion.section>
        )}
      </InView>

      <div className="mobile-main-list">
            <InView {...inViewOptions}>
              {({ ref, inView }) => (
                <motion.section
                  className="customers-reviews components-Home-Reviews-Reviews"
                  // key={i}
                  ref={ref}
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={containerItem}
                >
                  <Container>
                    <Row className="align-items-xl-center">
                      <Col lg={12}>
                        <Slider className="reviews-slider"  {...settings} >
                          {arr.map(({node}, i) => {
                            return (
                              <div className="review-video main" key={i}>
                                <motion.div
                                  custom={i}
                                  variants={contentItem}
                                >
                                  <img loading="lazy" src={node.Image.childImageSharp.gatsbyImageData.images.fallback.src} alt={`${node.Name} - Martyn Gerrard`} />
                                  <Video title={`${node.Name} Review`} url={node.Embed_Video_URL}/>
                                </motion.div>
                                <p>{node.Name}</p>
                              </div>
                            );
                          })}
                        </Slider>
                      </Col>
                    </Row>
                  </Container>
                </motion.section>
              )}
            </InView>
      </div>
    </div>
  );
}

export default AllReviews
