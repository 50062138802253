import { Link } from "@StarberryUtils";
import PropTypes from "prop-types";
import React from "react";
import {Col, Row, Container } from 'react-bootstrap';
import  "../Intro/Intro.scss";
//import "animate.css/animate.min.css";
import VideoPlay from "../../Play/news-video-with-background";
import ReactMarkdown from "react-markdown"
import Breadcrumbs from "../../MgServices/Breadcrumb/news-breadcrumb"

import { NewsDate, GetURL } from "../../common/site/functions";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";


import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";

const NewsStaticPage = (props, { siteTitle }) => {


  const shareurl = typeof window !== 'undefined' ? window.location.href : ''

  const [Shareicons,setShareicons] = React.useState(false);
  const openShareicons = () => {
    setShareicons(true);
    if(Shareicons === true) {
      setShareicons(false);
    }
  }

  let url = '/'
  if(props.Right_Side_Block_CTA_Link) {
    url = GetURL(props.Right_Side_Block_CTA_Link.id)
  }
  
return(
  <section className="managing-sec managing-intro static-news-page static-content-two">
    <div className="rental-market">
      <Container>
        <Row>
          <Col className="static-details-top-head" xl={8}>
          <Breadcrumbs url={props.url} tag="details" Title={props.Title} category={props.category}/>
          <h1>{props.Title}</h1>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col className="order-2 order-xl-1" xl={8}>
            <div className="rental-post news-content">
              {props.Content.map((content, i) => {  
                  return <>
                    {content.Content !== null &&
                      <ReactMarkdown source={content.Content ? content.Content : ''} allowDangerousHtml className="editor"/>
                    }

                    {content.Background_Image ?
                      <VideoPlay image={content.Background_Image.url} url_1={content.Embed_Video_URL} url_2={content.Upload_Video} title={props.Title}/> : ''
                    }
                  </>
              })}

            </div>
          </Col>
          <Col className="order-1 order-xl-2" xl={4}>
          <div className="news-details-static-sidebar">
            <ul className="detail-list">
              {props.Date ? <li><strong className="highlited">Date Posted</strong><span className="desc news-date">{NewsDate(props.Date)}</span></li> : '' }

              {props.Category ? <li><strong className="highlited">Category</strong><span className="desc">{props.Category[0].Name}</span></li> : '' }

              {props.Author ? <li><strong className="highlited">Author</strong><span className="desc">{props.Author}</span></li> : '' }

              <li>
              <span className="desc news-details-social-share" onClick={openShareicons} role="presentation">
              <i className="icon-share"></i>Share
                        {Shareicons &&
                            <div onClick={openShareicons} className="property-share-social-icons" role="presentation">
                              <FacebookShareButton url={shareurl} className="my-share-button facebook-share">
                                  <FacebookIcon size={32} round={false} borderRadius={`10`} />
                              </FacebookShareButton>
                              <TwitterShareButton url={shareurl} className="my-share-button twitter-share">
                                  <TwitterIcon size={32} round={false} borderRadius={`10`} />
                              </TwitterShareButton>
                              <LinkedinShareButton url={shareurl} className="my-share-button linkedin-share">
                                  <LinkedinIcon size={32} round={false} borderRadius={`10`} />
                              </LinkedinShareButton>
                              <WhatsappShareButton url={shareurl} className="my-share-button whatsapp-share">
                                  <WhatsappIcon size={32} round={false} borderRadius={`10`} />
                              </WhatsappShareButton>
                            </div>
                        }
              </span>
              {props.Upload_File ? <span className="desc">
              <a href={props.Upload_File.url} target="_blank" rel="noreferrer"><i className="icon-print"></i>Print</a>
              </span> : '' }
              </li>
            </ul>

            {props.Right_Side_Block_Title != null || props.Speak_with != null ? 
            <div className="get-quote d-none d-xl-block">
              {props.Right_Side_Block_Title ? <h3 className="get-title">{props.Right_Side_Block_Title}</h3> : '' }
              {props.Right_Side_Block_CTA_Label ? <Link to={`/${url}`} class="btn">{props.Right_Side_Block_CTA_Label}</Link> : '' }
              {props.Speak_with ?
              <>
              <p className="get-desc">We’re always here to help. Hit the button above or get in touch with {props.Speak_with.Name}.</p>

              <div className="get-profile d-flex align-items-center">
                <figure><img loading="lazy" src={`${props.Speak_with.Tile_Image.url}`} alt="profile-img" /></figure>
                <div className="prof-info">
                  <span className="name">{props.Speak_with.Name}</span>
                  <a href={`tel:${props.Speak_with.Phone}`}>{props.Speak_with.Phone}</a>
                </div>
              </div></> : '' }

            </div> : '' }
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </section>
)
}

NewsStaticPage.propTypes = {
  siteTitle: PropTypes.string,
}

NewsStaticPage.defaultProps = {
  siteTitle: ``,
}

export default NewsStaticPage
