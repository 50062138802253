import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import {Col, Row, Container,Dropdown } from 'react-bootstrap';
import ReactMarkdown from "react-markdown"
import { Link } from "@StarberryUtils";

const LocalLifeCategories=()=> {

    const data = useStaticQuery(graphql`
      query{
        allStrapiLocalLifeCategories(filter: {local_life_things_to_dos: {elemMatch: {Featured: {eq: true}}}, Publish: {eq: true}}, sort: {fields: Sort, order: ASC}) {
        edges {
          node {
            Sort
            Name
            URL
            Alias
            strapiId
            local_life_things_to_dos {
              area_guide
            }
          }
        }
	      }
      }
    `);

    const list = data.allStrapiLocalLifeCategories.edges
  
      return(
        <>
            <div className="banner-local-love-list d-none d-md-block">
                {list.map(({node}, i) => {
                    return (
                        <Link to={`../loving-life/${node.URL}`} className="btn">{node.Name}</Link>
                    )
                })}
                
                <Link to="/about/local-recommendation/" className="btn reverse-btn">Recommend a Business</Link>
            </div>

            <div className="banner-local-love-list-dropdown d-md-none">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  All
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {list.map(({node}, i) => {
                    return (
                      <Dropdown.Item><Link to={`../loving-life/${node.URL}`}>{node.Name}</Link></Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
        </>
      )
    }
  export default LocalLifeCategories