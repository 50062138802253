import React, { useRef, useState, useEffect } from "react"
import { Link } from "@StarberryUtils"
import { Form, Button } from "react-bootstrap"
import { getUser } from "@myaccountServices/store/utils"

import InputField from "./elements/input"
import SelectField from "./elements/select"
import RadioField from "./elements/radio"
import TextAreaField from "./elements/textarea"
import CheckboxField from "./elements/checkbox"
import PaymentCheckbox from "./elements/payment-checkbox"

import ButtonField from "./elements/button"
import HtmlBox from "./elements/html"
import ReCaptchaBox from "./elements/recaptcha"
import { postFormData } from "./api/Api"
import $ from "jquery"
import _ from "lodash"

import "../../scss/forms.scss"

import axios from "axios"
import * as qs from "query-string"

function RecommForm(props) {
  const userData = getUser()
  const [validated, setValidated] = useState(false)
  const [showerror, setShowerror] = useState(false)
  const [showthankyou, setThankyou] = useState(false)

  const [formvalues, setFormvalues] = useState("")
  const [department, setDepartment] = useState("")
  const [paymentype, setPaymentype] = useState("")
  const [token, setToken] = useState("")

  const [progress, setProgess] = useState(0) // progess bar
  const el = useRef() // accesing input element

  const myRef = React.createRef()

  const recaptchaRef = React.createRef()

  var all_recomm = [
    "Lettings & Property Management",
    "Block Management/Service Charge",
    "Sales",
  ]
  const fields = [
    {
      element: "config",
      formname: "Make Payment",
      error_text: "Highlighted fields are required",
      success_text:
        "Thank you for your interest. A member of our team will contact you shortly.",
      email_temp_user: "makepayment_user",
      email_temp_admin: "makepayment_admin",
      email_server_func: "paymentform",
      event_tracking: "makepayment",
      page_url: "/about/local-recommendation/",
    },
    {
      grpmd: "12",
      label: "",
      placeholder: "Select Department",
      name: "department",
      element: "select",
      required: true,
      values: all_recomm,
      class: "mb-4",
    },
    {
      text: '<p class="label"><label class="form-label form-label" for="validationdepartment">Please select the type of payment *</label></p>',
      element: "html",
      class: "payment-heading type-1",
    },
    {
      grpmd: "12",
      placeholder: "Holding Deposit",
      name: "payment_type",
      type: "text",
      label: "Holding Deposit",
      value: "Holding Deposit",
      element: "checkbox",
      fieldClass: "",
      labelClass: "content_b-18",
      step: "type-1",
    },
    {
      grpmd: "12",
      label: "Landlords Fees",
      placeholder: "Landlords Fees",
      name: "payment_type",
      type: "text",
      value: "Landlords Fees",
      element: "checkbox",
      fieldClass: "",
      labelClass: "content_b-18",
      step: "type-1",
    },
    {
      grpmd: "12",
      label: "Major Works",
      placeholder: "Major Works",
      name: "payment_type",
      type: "text",
      value: "Major Works",
      element: "checkbox",
      fieldClass: "",
      labelClass: "content_b-18",
      step: "type-1",
    },
    {
      grpmd: "12",
      label: "EPC",
      placeholder: "EPC",
      name: "payment_type",
      type: "text",
      value: "EPC",
      element: "checkbox",
      fieldClass: "",
      labelClass: "content_b-18",
      step: "type-1",
    },
    {
      grpmd: "12",
      label: "Additional Marketing Package",
      placeholder: "Additional Marketing Package",
      name: "payment_type",
      type: "text",
      value: "Additional Marketing Package",
      element: "checkbox",
      fieldClass: "",
      labelClass: "content_b-18",
      lastchild: "last-child",
      step: "type-1",
    },
    {
      grpmd: "12",
      label: "Other",
      placeholder: "Other",
      name: "payment_type",
      type: "text",
      value: "Other",
      element: "checkbox",
      fieldClass: "",
      labelClass: "content_b-18",
      step: "type-1",
    },
    {
      text: '<p class="label"><label class="form-label form-label" for="validationdepartment">Please select the type of payment *</label></p>',
      element: "html",
      class: "payment-heading type-3",
    },
    {
      grpmd: "12",
      label: "EPC",
      placeholder: "EPC",
      name: "payment_type_sales",
      type: "text",
      value: "EPC",
      element: "checkbox",
      fieldClass: "",
      labelClass: "content_b-18",
      step: "type-3",
    },
    {
      grpmd: "12",
      label: "VUZE Marketing Campaign",
      placeholder: "VUZE Marketing Campaign",
      name: "payment_type_sales",
      type: "text",
      value: "VUZE Marketing Campaign",
      element: "checkbox",
      fieldClass: "",
      labelClass: "content_b-18",
      lastchild: "last-child",
      step: "type-3",
    },
    {
      grpmd: "12",
      label: "Property Staging",
      placeholder: "Property Staging",
      name: "payment_type_sales",
      type: "text",
      value: "Property Staging",
      element: "checkbox",
      fieldClass: "",
      labelClass: "content_b-18",
      step: "type-3",
    },
    {
      grpmd: "12",
      label: "Other",
      placeholder: "Other",
      name: "payment_type_sales",
      type: "text",
      value: "Other",
      element: "checkbox",
      fieldClass: "",
      labelClass: "content_b-18",
      step: "type-3",
    },
    // {
    //   text: '<p class="label"><label class="form-label form-label" for="validationdepartment">Select this option for Service Charge Payments only *</label></p>',
    //   element: "html",
    //   class: "payment-heading type-2",
    // },
    {
      grpmd: "12",
      placeholder: "Service Charge",
      name: "service_charge",
      type: "text",
      label: "Service Charge",
      value: "Service Charge",
      element: "checkbox",
      required: true,
      fieldClass: "",
      labelClass: "content_b-18",
      step: "type-2 service-charge-only",
    },
    {
      grpmd: "12",
      placeholder: "Reserve Fund",
      name: "service_charge",
      type: "text",
      label: "Reserve Fund",
      value: "Reserve Fund",
      element: "checkbox",
      required: true,
      fieldClass: "",
      labelClass: "content_b-18",
      step: "type-2 service-charge-only",
    },
    {
      grpmd: "12",
      placeholder: "Major Works",
      name: "service_charge",
      type: "text",
      label: "Major Works",
      value: "Major Works",
      element: "checkbox",
      required: true,
      fieldClass: "",
      labelClass: "content_b-18",
      step: "type-2 service-charge-only",
    },
    {
      text: '<p class="label"><label class="form-label form-label" for="validationdepartment">Select this option if you are paying Ground Rent and/or Insurance *</label></p>',
      element: "html",
      class: "payment-heading type-2",
    },
    {
      grpmd: "12",
      placeholder: "Ground Rent",
      name: "gr_insurance",
      type: "text",
      label: "Ground Rent",
      value: "Ground Rent",
      element: "checkbox",
      required: true,
      fieldClass: "",
      labelClass: "content_b-18",
      step: "type-2",
    },
    {
      grpmd: "12",
      placeholder: "Insurance",
      name: "gr_insurance",
      type: "text",
      label: "Insurance",
      value: "Insurance",
      element: "checkbox",
      required: true,
      fieldClass: "",
      labelClass: "content_b-18",
      step: "type-2",
    },
    {
      text: '<p class="label"><label class="form-label form-label" for="validationdepartment">Your details</label></p>',
      element: "html",
      class: "payment-heading below-details",
    },
    {
      grpmd: "12",
      label: "Name",
      placeholder: "Name *",
      name: "name",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      fieldClass: "",
      labelClass: "content_b-18",
      step: "below-details",
    },
    {
      grpmd: "12",
      label: "Email Address",
      placeholder: "Email Address *",
      name: "email",
      type: "email",
      element: "input",
      required: true,
      patternchk: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
      class: "",
      step: "below-details",
    },

    {
      grpmd: "12",
      placeholder: "Property Address / Payment Ref *",
      name: "paymentref",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      fieldClass: "",
      labelClass: "content_b-18",
      step: "below-details",
    },
    {
      grpmd: "12",
      placeholder: "Amount *",
      name: "amount",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[+-]?([0-9]+.?[0-9]*|.[0-9]+)$",
      fieldClass: "",
      labelClass: "content_b-18",
      step: "below-details payment-amount",
    },
    {
      text: '<p class="label"><label class="form-label form-label" for="validationdepartment">Confirm payment is for *</label></p>',
      element: "html",
      class: "payment-heading below-details",
    },
    {
      grpmd: "12",
      placeholder: "",
      name: "confirm_payment",
      type: "text",
      element: "textarea",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      fieldClass: "",
      labelClass: "content_b-18",
      step: "below-details payment-confirm",
    },

    {
      name: "submit details",
      type: "submit",
      element: "button",
      value: "submit",
      class: "btn",
      formclass: "",
    },
    {
      text: '<p>By clicking Submit, you agree to our <a href="/terms-and-conditions/"> Terms &amp; Conditions </a> and <a href="/privacy-policy/"> Privacy Policy </a></p>',
      element: "html",
      class: "terms",
    },
    {
      element: "captcha",
      class: "py-2 captcha-class",
      captchaRef: recaptchaRef,
    },
  ]

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }
  const handletypechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
    setDepartment(event.target.value)
  }
  const radiochange = event => {
    // remove initial empty spaces
    $(".payment-form-wrap .form-group.radio-wrap").removeClass("has-error")
    event.target.value = event.target.value.trimStart()
    if (event.target.value != "Service Charge") {
      setPaymentype(event.target.value)
    }
  }
  const CheckBoxChange = event => {
    $(".payment-form-wrap .form-group.radio-wrap").removeClass("has-error");
    var ids = $('.radio-wrap input:checked').map((i, chk) => chk.value).get().join(', ');
    if( ids == "Other" || ids == ''|| ids.length == 0 ) {
      $('.below-details.payment-confirm textarea').val('');
    } else {      
      $('.below-details.payment-confirm textarea').val(ids);
    }   
    setPaymentype(event.target.value) 
  }
  useEffect(() => {
    
    var checked_payment_type = document.querySelector(
      'input[name="payment_type"]:checked'
    )
    var checked_payment_type_sales = document.querySelector(
      'input[name="payment_type_sales"]:checked'
    )
    var checked_service_charge = document.querySelector(
      'input[name="service_charge"]:checked'
    )
    var checked_gr_insurance = document.querySelector(
      'input[name="gr_insurance"]:checked'
    )
    if (department) {
      $(".make-payment-form .below-details").show()
      //$('input[name="payment_type"]').prop('checked' , false);
    }
    if (department == "") {
      $(".make-payment-form .below-details").hide()
      $(".make-payment-form .type-1").hide()
      $(".make-payment-form .type-2").hide()
      $(".make-payment-form .type-3").hide()
      
    }
    if (department && department == "Lettings & Property Management") {
      $(".payment-form-wrap .form-group.radio-wrap").removeClass("has-error");
      $('.below-details.payment-confirm textarea').val('');
      $(".make-payment-form .type-1").css("display", "inline-block")
      $(".make-payment-form .type-2").hide()
      $(".make-payment-form .type-3").hide()
      $('.radio-wrap input').prop('checked', false);
    }
    if (department && department == "Block Management/Service Charge") {
      $(".payment-form-wrap .form-group.radio-wrap").removeClass("has-error");
      $('.below-details.payment-confirm textarea').val('');
      $(".make-payment-form .type-1").hide()
      $(".make-payment-form .type-3").hide()
      $(".make-payment-form .type-2").css("display", "inline-block")
      $('.radio-wrap input').prop('checked', false);
    }
    if (department && department == "Sales") {
      $(".payment-form-wrap .form-group.radio-wrap").removeClass("has-error");
      $('.below-details.payment-confirm textarea').val('');
      $(".make-payment-form .type-2").hide()
      $(".make-payment-form .type-1").hide()
      $(".make-payment-form .type-3").css("display", "inline-block")
      $('.radio-wrap input').prop('checked', false);
    }
  }, [department])

  useEffect(() => {
    if (token !== "") {
      const processFromData = async () => {
        formvalues["g-recaptcha-response"] = token
        formvalues["extra"] = JSON.stringify(formvalues)

        let formData = new FormData()

        formData.append("data", JSON.stringify(formvalues))

        postFormData(formData).then(apiRes => {
          console.log("apiRes", apiRes)
          // setup instId, cartId, amount and testmode
          // cartId = apiRes.id
          // name = apiRes.name
          // email = apiRes.email
          // amount = formvalues['amount']
          // testmode = 100 | 0
          let instId = "290035"
          let accId1 = ""
          let cartId = apiRes.id
          let wp_name = apiRes.name
          let wp_email = apiRes.email
          let wp_amount = formvalues["amount"]
          let testmode = 0 // 100 - testing
          if (formvalues["department"] == "Lettings & Property Management") {
            accId1 = "THENORTHLONDM1"
          }
          if (formvalues["department"] == "Block Management/Service Charge") {
            accId1 = "THENORTHLONDM3"
          }
          if (formvalues["department"] == "Sales") {
            accId1 = "THENORTHLONDM4"
          }

          let urlData = {}
          // lets send mail
          const axiosOptions_email = {
            url: "/.netlify/functions/server/" + fields[0].email_server_func,
            method: "post",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: qs.stringify(formvalues),
          }

          Object.entries(formvalues).map(([k, v]) => (urlData[`M_${k}`] = [v]))

          let paymenyQuery = qs.stringify(urlData)

          axios(axiosOptions_email)
            .then(response => {
              console.log("mail sent!")
            })
            .catch(err => console.log(err))
          window.location.replace(
            "https://secure.worldpay.com/wcc/purchase?" +
              paymenyQuery +
              "&instId=" +
              instId +
              "&accId1=" +
              accId1 +
              "&cartId=" +
              cartId +
              "&amount=" +
              wp_amount +
              "&currency=GBP&testMode=" +
              testmode +
              "&name=" +
              wp_name +
              "&email=" +
              wp_email +
              "&successURL=" +
              encodeURIComponent(
                "https://www.martyngerrard.co.uk/property-services/make-a-payment/success"
              ) +
              "&failureURL=" +
              encodeURIComponent(
                "https://www.martyngerrard.co.uk/property-services/make-a-payment/failure"
              )
          )
          //window.location.replace("https://secure-test.worldpay.com/wcc/purchase?"+paymenyQuery+"&instId="+instId+"&cartId="+cartId+"&amount="+wp_amount+"&currency=GBP&testMode="+testmode+"&name="+wp_name+"&email="+wp_email);
        })

        // // lets send mail
        // const axiosOptions_email = {
        //   url: '/.netlify/functions/server/'+fields[0].email_server_func,
        //   method: "post",
        //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
        //   data: qs.stringify(formvalues),
        // }

        // axios(axiosOptions_email)
        //   .then(response => {
        //     console.log('mail sent!')
        //   })
        //   .catch(err =>
        //     console.log(err)
        //   );
        // });

        // const url = typeof window !== 'undefined' ? window.location.href : ''
        // if (url.indexOf("property") > -1 && fields[4].event_tracking_decider) {
        //   fields[0].formname = 'Get mortgage help';
        // }
        // // tracking event
        // window.dataLayer = window.dataLayer || [];
        // window.dataLayer.push({
        //   'event': 'formSubmit',
        //   'formType': 'form-'+fields[0].event_tracking,
        //   'formId': 'form-'+fields[0].event_tracking,
        //   'formName': fields[0].formname,
        //   'formLabel': fields[0].formname
        // });

        // setShowerror(false);
        // setThankyou(true);
        // $('.payment-form-wrap').hide();
        // $(".make-payment-form .below-details").hide();
        // $(".make-payment-form .type-1").hide();
        // $(".make-payment-form .type-2").hide();
        // $(".make-payment-form .type-3").hide();
        // myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }
      processFromData()
    }
  }, [token])

  const handleonVerify = token => {
    console.log("captcha verified")
    setToken(token)
  }

  const handleSubmit = event => {
    const form = event.currentTarget
    var selected_payment = $('.below-details.payment-confirm textarea').val();
    if (selected_payment == "" || selected_payment == undefined) {
      $(".payment-form-wrap .form-group.radio-wrap").addClass("has-error")
    } else {
      $(".payment-form-wrap .form-group.radio-wrap").removeClass("has-error")
    }
    if(paymentype == "Other") {
      $(".payment-form-wrap .form-group.radio-wrap").removeClass("has-error")
    }
    
    //$(".payment-form-wrap .form-group.radio-wrap.service-charge-only").removeClass("has-error")
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
      setShowerror(true)
      setValidated(true)
      setThankyou(false)
      myRef.current.scrollIntoView({ behavior: "smooth", block: "end" })
    } else {
      event.preventDefault()

      const formsdata = event.target
      const json = {}
      Object.keys(formsdata).map(
        key =>
          (json[formsdata[key].name] = formsdata[key].checked
            ? "yes"
            : formsdata[key].value)
      )

      json["email_temp_user"] = fields[0].email_temp_user
      json["email_temp_admin"] = fields[0].email_temp_admin
      json["formname"] = fields[0].formname
      json["g-recaptcha-response"] = token
      json["extra"] = _.omit(json, ["formname", "name", "email", "telephone"])

      setFormvalues(json)

      recaptchaRef.current.execute()

      setValidated(false)

      // reset form
      const form = event.target
      form.reset()
    }
  }

  const makePayment = () => {
    if (typeof window !== "undefined") {
      window.location.reload()
    }
  }

  return (
    <div className="career-form form stbform valuation-main recommendation-form make-payment-form">
      <div ref={myRef} />

      {showerror && (
        <div className="alert-error">
          <p>{fields[0].error_text}</p>
        </div>
      )}

      {showthankyou && (
        <div className="alert-success">
          {/* <p>{fields[0].success_text}</p> */}
          <div className="payment-success">
            <i className="icon-circle-tick"></i>
            <h3>Your payment has been processed successfully</h3>
            <Link to="/" class="btn">
              Back to homepage
            </Link>
            <a class="btn btn-success" onClick={() => makePayment()}>
              Make Another payment
            </a>
          </div>
        </div>
      )}
      <div className="payment-form-wrap">
        <p className="payment-head"><strong>
          Please select the Department you want to make payment to below. You
          will then be able to select specific options.
          </strong>
        </p>
        <Form
          className="form"
          name={fields[0].formname}
          action="/thank-you/"
          method="post"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <div className="step-block">
            <input type="hidden" name="form_name" value={fields[0].formname} />
            <input type="hidden" name="form_type" value={fields[0].form_type} />
            <input type="hidden" name="bot-field" />
            {fields.map((field, index) => {
              if ("input" === field.element) {
                return (
                  <InputField
                    name={field.name}
                    grpmd={field.grpmd}
                    ref={field.ref}
                    type={field.type}
                    fieldClass={field.class}
                    placeholder={field.placeholder}
                    labelClass={field.labelClass}
                    required={field.required}
                    key={`${field.element}~${index}`}
                    pattern={field.patternchk}
                    handlechange={handlechange}
                    step={field.step}
                    defaultValue={field.defaultValue}
                  />
                )
              }

              if ("select" === field.element) {
                return (
                  <SelectField
                    name={field.name}
                    grpmd={field.grpmd}
                    ref={field.ref}
                    required={field.required}
                    fieldClass={field.class}
                    label={field.label}
                    placeholder={field.placeholder}
                    values={field.values}
                    key={`${field.element}~${index}`}
                    handlechange={handletypechange}
                    componentprops={props}
                  />
                )
              }
              if ("textarea" === field.element) {
                return (
                  <TextAreaField
                    name={field.name}
                    grpmd={field.grpmd}
                    ref={field.ref}
                    rows={field.rows}
                    fieldClass={field.class}
                    labelClass={field.labelClass}
                    placeholder={field.placeholder}
                    required={field.required}
                    key={`${field.element}~${index}`}
                    handlechange={handlechange}
                    step={field.step}

                  />
                )
              }
              if ("checkbox" === field.element) {
                return (
                  <PaymentCheckbox
                    name={field.name}
                    ref={field.ref}
                    value={field.value}
                    fieldClass={field.class}
                    checked={field.checked}
                    placeholder={field.placeholder}
                    required={field.required}
                    key={`${field.name}~${index}`}
                    handlechange={CheckBoxChange}
                    lastchild={field.lastchild}
                    step={field.step}
                  />
                )
              }
              if ("radio" === field.element) {
                return (
                  <RadioField
                    name={field.name}
                    ref={field.ref}
                    value={field.value}
                    fieldClass={field.class}
                    checked={field.checked}
                    placeholder={field.placeholder}
                    required={field.required}
                    key={`${field.name}~${index}`}
                    handlechange={radiochange}
                    lastchild={field.lastchild}
                    step={field.step}
                  />
                )
              }
              if ("html" === field.element) {
                return (
                  <HtmlBox
                    text={field.text}
                    fieldClass={field.class}
                    key={`${field.element}~${index}`}
                  />
                )
              }
              if ("captcha" === field.element) {
                return (
                  <ReCaptchaBox
                    fieldClass={field.class}
                    captRef={field.captchaRef}
                    key={`${field.element}~${index}`}
                    handleonVerify={handleonVerify}
                  />
                )
              }
              if ("button" === field.element) {
                return (
                  <ButtonField
                    name={field.name}
                    fieldClass={field.class}
                    formclass={field.formclass}
                    type={field.type}
                    value={field.value}
                    key={`${field.element}~${index}`}
                  />
                )
              }
            })}
          </div>
        </Form>
      </div>
    </div>
  )
}

const RecommFormPage = props => <RecommForm />

export default RecommFormPage
