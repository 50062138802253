import { Link } from "@StarberryUtils";
import React, { useEffect, useState } from "react";
import {Col, Row, Container } from 'react-bootstrap';
import  "../Intro/Intro.scss";
import ReactMarkdown from "react-markdown"
import Breadcrumbs from "../../MgServices/Breadcrumb/news-breadcrumb"
import Form from "../../forms/career-form"
import share from "../../../images/share.png";
import print from "../../../images/print.png";
import $ from 'jquery'

import { inViewOptions } from '../../utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";


import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";

const containerVariants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4
    }
  }
}
const contentVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.2,
      ease: [0.33, 1, 0.68, 1],
      duration: 0.8
    }
  }
}
const sidebarVariants = {
  hidden: {
    opacity: 0,
    y: -30,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.4,
      ease: [0.33, 1, 0.68, 1],
      duration: 0.8
    }
  }
}
const MotionColumn = motion(Col)

const StaticJobPage = (props) => {

  const shareurl = typeof window !== 'undefined' ? window.location.href : ''

  const [Shareicons,setShareicons] = React.useState(false);
  const openShareicons = () => {
    setShareicons(true);
    if(Shareicons === true) {
      setShareicons(false);
    }
  }

  
  useEffect(() => {
    $(".career_job_apply").click(function() {
      $('html, body').animate({
        scrollTop: $("#job-detail-career-form").offset().top - 50
    }, 1000);
    })
  })
  
return(
  <InView {...inViewOptions}>
    {({ ref, inView }) => (
      <motion.section
        className="managing-sec managing-intro static-news-page"
        ref={ref}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={containerVariants}
      >
        <div className="rental-market">
          <Container>
            <Breadcrumbs url={props.alias} tag="contact" Title={props.slug} class="person-detail-breadcrumb"/>
            <Row>
              <MotionColumn className="order-1 order-xl-1" xl={8} variants={contentVariants}>
                <div className="rental-post news-content">
                  <h2>{props.Title}</h2>

                  <div className="salary-location">
                    <table>
                    {props.Location &&
                      <tr className="first">
                        <td><p className="strong">Location</p></td>
                        <td><p>{props.Location}</p></td>
                      </tr>
                    }
                    {props.Salary &&
                      <tr>
                        <td><p className="strong">Salary</p></td>
                        <td><p>{props.Salary}</p></td>
                      </tr>
                    }
                    </table>
                  </div>

                  <ReactMarkdown source={props.Job_Description ? props.Job_Description : ''} allowDangerousHtml className="job-desc"/>
                  {props.Requirements ? <><p className="requirement-title">Requirements</p>
                  <ReactMarkdown source={props.Requirements ? props.Requirements : ''} allowDangerousHtml className=""/></> : '' }

                  <div className="career-form" id="job-detail-career-form">
                    <h3>Apply for this Job</h3>
                    <ReactMarkdown source={props.Apply_for_this_Job_Content ? props.Apply_for_this_Job_Content : ''} allowDangerousHtml className=""/>
                    <Form title={props.Title}/>
                  </div>

                </div>
              </MotionColumn>
              <MotionColumn className="order-2 order-xl-2" xl={4} variants={sidebarVariants}>
                <div className="get-quote d-xl-block">
                  <h3 className="get-title">Are you ready to apply for this position?</h3>
                  <a href="javascript:void(0)" class="btn career_job_apply">apply for job</a>
                  <p className="get-desc">We’re always here to help. Hit the button above to send us your CV.</p>

                  {props.Speak_with ?
                  <div className="get-profile d-flex align-items-center">
                    {props.Speak_with.Tile_Image ? <figure><img loading="lazy" src={`${props.Speak_with.Tile_Image.url}`} alt="profile-img" /></figure> : '' }
                    <div className="prof-info">
                      <span className="name"><Link to={`/about/our-people/${props.Speak_with.URL}`}>{props.Speak_with.Name}</Link></span>
                      <a href={`tel:${props.Speak_with.Phone}`}>{props.Speak_with.Phone}</a>
                    </div>
                  </div> : '' }
                  <div className="share-print">
                    <Row>
                      <Col md={6}>
                        <div className="">
                          <p onClick={openShareicons}>
                            <img loading="lazy" src={share} alt="" /><span>Share</span>
                            {Shareicons &&
                                <div onClick={openShareicons} className="property-share-social-icons">
                                  <FacebookShareButton url={shareurl} className="my-share-button facebook-share">
                                      <FacebookIcon size={32} round={false} borderRadius={`10`} />
                                  </FacebookShareButton>
                                  <TwitterShareButton url={shareurl} className="my-share-button twitter-share">
                                      <TwitterIcon size={32} round={false} borderRadius={`10`} />
                                  </TwitterShareButton>
                                  <LinkedinShareButton url={shareurl} className="my-share-button linkedin-share">
                                      <LinkedinIcon size={32} round={false} borderRadius={`10`} />
                                  </LinkedinShareButton>
                                  <WhatsappShareButton url={shareurl} className="my-share-button whatsapp-share">
                                      <WhatsappIcon size={32} round={false} borderRadius={`10`} />
                                  </WhatsappShareButton>
                                </div>
                            }
                          </p>
                        </div>
                      </Col>
                      <Col md={6}>
                        {props.Attach_File ?
                        <div className="">
                          <p>
                            <a href={props.Attach_File.url} target="_blank"><img loading="lazy" src={print} alt="" /><span>Print</span></a>
                          </p>
                        </div> : '' }
                      </Col>
                    </Row>
                  </div>
                </div>
              </MotionColumn>
            </Row>
          </Container>
        </div>
      </motion.section>
    )}
  </InView>
)
}

export default StaticJobPage
