import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import {Col, Row, Container } from 'react-bootstrap';
import ReactMarkdown from "react-markdown"
import { Link } from "@StarberryUtils";

const LocalLifeCategories=()=> {

    const data = useStaticQuery(graphql`{
  allStrapiLocalLifeCategories(
    filter: {local_life_things_to_dos: {elemMatch: {Featured: {eq: true}}}, Publish: {eq: true}}
  ) {
    edges {
      node {
        Name
        URL
        Alias
        strapiId
        Intro_Content
        Meta_Description
        Meta_Title
        local_life_things_to_dos {
          area_guide
        }
      }
    }
  }
  allStrapiRestaurants(filter: {Publish: {eq: true}, Featured: {eq: true}}) {
    edges {
      node {
        Address
        Featured
        Name
        URL
        Tile_Image_Label
        Short_Description
        Tile_Image {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 120, layout: CONSTRAINED)
          }
        }
        strapiId
        Category {
          Name
          URL
          id
        }
      }
    }
  }
}
`);

    const list = data.allStrapiLocalLifeCategories.edges
    const reataurants = data.allStrapiRestaurants.edges
  
      return (
        <div className="local-life-new-page-listing local-life-area-landing-page    ">
          
          

              {list.map(({node}, i) => {
                  let cat = node.URL
                  return <>
                    <Row>
                    
                      <Col md={12}>
                          <h2 className="sec-title">{node.Name}</h2>
                      </Col>
                      <Col md={8}>
                          <ReactMarkdown source={node.Intro_Content} allowDangerousHtml />
                      </Col>
                      <Col md={4}>
                          <div className="view-more">
                          <Link to={`${node.URL}`} className="btn btn-success">View More</Link>
                          </div>
                      </Col>
                  </Row> 
                  <div className="mg-favourites mg-fav-landing">
                  <div className="tab-content">
                  <Row className="mg-things-do-list">
                      {reataurants.map(({node}, i) => {
                          return <>
                              {node.Category && node.Category.URL == cat &&
                                  <Col xl={6}>
                                      <Link to={`/about/loving-life/${cat}/${node.URL}`} className="link">
                                          <div className={`favourite-item d-flex no-border`}>
                                              <figure>
                                                  {node.Tile_Image ? <img loading="lazy" src={node.Tile_Image.childImageSharp.gatsbyImageData.images.fallback.src} alt={`${node.Name} - Martyn Gerrard`} /> : '' }
                                                  {node.Tile_Image_Label ? <span className="tag-abs text-uppercase">{node.Tile_Image_Label}</span> : '' }
                                              </figure>
                                              <div className="info">
                                                  <h4 className="info-title">{node.Name}</h4>
                                                  <span className="add-txt">{node.Address?.substring(0, 50)} {node.Address?.length > 50 ? '...' : ''}</span>
                                                  <ReactMarkdown source={node.Short_Description} allowDangerousHtml className=""/>
                                              </div>
                                          </div>
                                      </Link>
                                  </Col>
                              }
                          </>;
                      })}
                  </Row>
                  </div>
                  </div>
                  </>;
              })}
          <section className="fixed-btns d-flex d-md-none">
            <Link to="/about/local-recommendation/" className={`full-width-cta-person btn book-btn`} onClick={null}>Recommend a Business</Link>
            {/* <Link to="#" className="btn ask-btn btn-success" onClick={null}>Book an appointment</Link> */}
          
        </section>
          </div>
      );
    }
  export default LocalLifeCategories